import { Injectable } from '@angular/core';
import { BluetoothLE, Status } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { of, Observable, throwError } from 'rxjs';
import { catchError, concatMap, mergeMap, retryWhen } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { BmResultBloodPressure } from '../devices.model';

@Injectable()
export class BmService {
  service = '1810';
  characteristic = '2A35';

  measurements: BmResultBloodPressure[] = [];

  constructor(private bluetoothLe: BluetoothLE) {}

  /* eslint-disable no-bitwise */
  readMeasurement(inBuf: Uint8Array): BmResultBloodPressure {
    const systolic = (inBuf[1] & 255) + (inBuf[2] & 255);
    const diastolic = (inBuf[3] & 255) + (inBuf[4] & 255);
    // const pulse = (inBuf[14] & 255) + (inBuf[15] & 255);
    const year = (inBuf[7] & 255) + ((inBuf[8] & 255) << 8);
    const month = inBuf[9];
    const day = inBuf[10];
    const hour = inBuf[11];
    const min = inBuf[12];
    const sec = inBuf[13];
    const date = new Date(year, month - 1, day, hour, min, sec);
    const user = inBuf[16];

    return {
      type: 'blood_pressure',
      systolic,
      diastolic,
      device_time: dayjs(date).format('YYYY-MM-DDTHH:mm:ss'),
      user: user === 0 ? 1 : 2,
    };
  }
  /* eslint-enable no-bitwise */

  getMeasurementData(address: string): Observable<{
    status: Status | 'isDisconnected';
    data: BmResultBloodPressure[];
  }> {
    let subscribed = false;
    return of({}).pipe(
      concatMap(() =>
        this.bluetoothLe.subscribe({
          address,
          service: this.service,
          characteristic: this.characteristic,
        })
      ),
      concatMap((r) => {
        subscribed = true;
        if (r.status === 'subscribedResult') {
          const bytes = this.bluetoothLe.encodedStringToBytes(r.value);
          const measurement = this.readMeasurement(bytes);
          this.measurements.push(measurement);
        } else {
          this.measurements = [];
        }
        return of({ status: r.status, data: this.measurements });
      }),
      retryWhen((error) =>
        // retry subscription in case of an LE Secure Connections pairing model error
        error.pipe(mergeMap((e) => (e.error === 'subscription' ? of(true) : throwError(e))))
      ),
      catchError((error) =>
        subscribed && error?.error === 'isDisconnected'
          ? of({ status: error.error, data: this.measurements })
          : throwError(error)
      )
    );
  }
}
